import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { useStaticQuery, graphql } from 'gatsby';
import Link from 'src/components/Link';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import VerticalHeading from './VerticalHeading';

const HighlightedLinksWrapper = styled('div')`
    flex: 1;

    ${below.tablet_s} {
        padding-bottom: 200px;
    }

    ${above.desktop_s} {
        padding-top: 16px;
        padding-bottom: 48px;
    }
`;

const Heading = styled('span')`
    font-family: 'Tiempos';
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
`;

const LinksWrapper = styled('div')`
    margin-top: 24px;

    ${below.tablet_s} {
        width: 100%;
        margin-left: 20px;
    }

    ${above.tablet_s} {
        column-count: ${({ columns }) => columns};
        column-fill: auto;
        height: 355px;
    }
`;

const LinkWrapper = styled('div')`
    width: 100%;
    margin-bottom: 16px;
`;

const StyledLink = styled(Link)`
    color: ${theme.color.black};
    line-height: 1.31em;
    margin-right: 12px;
    font-weight: 400;
    width: 100%;

    &:hover {
        &::after {
            content: '${props => props.count}';
            font-size: 10px;
            margin-left: 5px;
            vertical-align: text-top;
        }
    }

    ${above.tablet_s} {
        line-height: 1em;
    }
`;

const LinksReportWrapper = styled('div')`
    flex: 1;
    display: flex;
`;

const ProductImage = styled('img')`
    width: 269px;
    height: 379px;
`;

const HighlightedProductWrapper = styled('div')`
    ${below.desktop_s} {
        display: none;
    }

    ${above.desktop_s} {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-left: 1px solid;
        width: 33.33%;
    }
`;

const StyledVerticalHeading = styled(VerticalHeading)`
    position: absolute;
    top: 80px;
    right: 39px;
`;

const LinksReport = ({ data, closeMenus }) => {
    const {
        heading,
        links,
        report,
    } = data;
    //TODO make image come from ImageSharp, making it scale dynamically and be sharper
    const image = `${process.env.MEDIA_UPLOAD}/thumbnails/${report?.report.ID}.png`;
    const link = report.report.acfData?.permalink || '';
    const columns = Math.floor(links.length / 10) + 1;
    const verticalHeading = report && report.heading;

    const { allCmsApiCategories } = useStaticQuery(
        graphql`
            query GetAllCategories {
              allCmsApiCategories {
                edges {
                  node {
                    alternative_id
                    count
                  }
                }
              }
            }
        `
    );

    const categories = allCmsApiCategories.edges.map(( category ) => {
        return category.node;
    });

    const GetCategoryCountById = (id) =>
        categories.map( ( category ) => {
            if (category.alternative_id === id) {
                return category.count;
            }
            return false;
        }).filter(Boolean)[0];

    return (
        <LinksReportWrapper>
            <HighlightedLinksWrapper>
                <Heading>
                    {heading}
                </Heading>
                <LinksWrapper columns={columns}>
                    {links.map((linkData, index) => {
                        const linkTitle = linkData.link.text;
                        const linkUrl = linkData.link.url;
                        const count = GetCategoryCountById(parseInt(linkData.link.linkId, 10));

                        return(
                            <LinkWrapper key={index} onClick={closeMenus}>
                                <StyledLink count={count} src={linkUrl}>{linkTitle}</StyledLink>
                            </LinkWrapper>
                        );
                    })}
                </LinksWrapper>
            </HighlightedLinksWrapper>
            <HighlightedProductWrapper>
                <Link src={link} onClick={closeMenus}>
                    <ProductImage alt="" src={image}/>
                </Link>
                <StyledVerticalHeading text={verticalHeading} />
            </HighlightedProductWrapper>
        </LinksReportWrapper>
    );
};

LinksReport.propTypes = {
    closeMenus: PropTypes.func.isRequired,
    data: PropTypes.shape({
        heading: PropTypes.string,
        links: PropTypes.arrayOf(PropTypes.object),
        report: PropTypes.shape({
            heading: PropTypes.string,
            report: PropTypes.shape({
                ID: PropTypes.number,
                acfData: PropTypes.shape({
                    permalink: PropTypes.string,
                }),
            }),
        }),
    }).isRequired
};

export default LinksReport;
